import React, {
  FC,
  useEffect,
  useState,
  useContext,
  useRef,
  ComponentProps,
} from "react";
import { ThemeContext } from "styled-components";
import { Spinner } from "../Spinner";
import { ButtonProps } from "../../types/button";
import { getSpinnerColor, getThemeValues } from "../../helpers/button";
import {
  StyledButton,
  StyledChildren,
  StyledExternalLink,
  StyledInternalLink,
  StyledLoading,
  StyledLoadingWrapper,
} from "./index.styled";

const TIME_BEFORE_SPINNER = 100;

/**
 * Explanation of internalLink usage here in `<Button>`
 *
 * - Pass in your internal link to style your clicky element
 *   just like a button but under the hood render as the
 *   `<InternalLink>` component
 *
 * Why should we not render internal links as buttons?
 *
 * - SEO and accessibility - no `<a href></a>` in the DOM tree
 * - Buttons are meant to do *more* than just change pages,
 *   such as submit forms or call functions or endpoints
 *
 * Why can't I just do `<a><button></button></a>` or
 * `<button><a></a></button>`?
 *
 * - It is against HTML5 spec and bad for accessibility
 *   to nest interactive elements
 */
export const Button: FC<ButtonProps> = ({
  type = "primary",
  disabled = false,
  children = "",
  htmlType = "button",
  fullWidth = false,
  loading = false,
  position = "",
  small = false,
  internalLink = "",
  externalLink = "",
  ...rest
}) => {
  const [isLoadingDisplayed, setisLoadingDisplayed] = useState(!!loading);
  const theme = useContext(ThemeContext);
  const timeout = useRef<number | undefined>();

  useEffect(() => {
    if (!loading && isLoadingDisplayed) {
      setisLoadingDisplayed(false);
    } else if (loading && !isLoadingDisplayed) {
      timeout.current = window.setTimeout(() => {
        if (loading && !isLoadingDisplayed) setisLoadingDisplayed(true);
      }, TIME_BEFORE_SPINNER);
      return () => clearTimeout(timeout.current);
    }
    return;
    // Ignoring dep warning since I'm not sure why we use this useEffect
    // and this works as-is so not touching :)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const { color, textColor, hollow, solidOnHover, hollowOnHover } =
    getThemeValues(type, theme);
  const spinnerColor = getSpinnerColor(type, theme, disabled);

  const linkProps: Omit<
    ComponentProps<typeof StyledExternalLink | typeof StyledInternalLink>,
    "href"
  > = {
    styleType: type,
    fullWidth: fullWidth,
    color: color,
    textColor: textColor,
    hollow: hollow,
    solidOnHover: solidOnHover,
    hollowOnHover: hollowOnHover,
    className: rest.className,
  };

  if (internalLink)
    return (
      <StyledInternalLink href={internalLink} {...linkProps}>
        {children}
      </StyledInternalLink>
    );

  if (externalLink)
    return (
      <StyledExternalLink href={externalLink} {...linkProps}>
        {children}
      </StyledExternalLink>
    );

  return (
    <StyledButton
      type={htmlType}
      /* adds "disabled" to the button in the DOM
          when `disabled` or `isLoadingDisplayed` */
      disabled={disabled || isLoadingDisplayed}
      /* checking `disabled` only here, don't want
          disabled styles when `isLoadingDisplayed` */
      addDisabledStyles={disabled}
      styleType={type}
      fullWidth={fullWidth}
      position={position}
      small={small}
      color={color}
      textColor={textColor}
      hollow={hollow}
      solidOnHover={solidOnHover}
      hollowOnHover={hollowOnHover}
      {...rest}
    >
      {isLoadingDisplayed ? (
        <StyledLoadingWrapper>
          <StyledLoading>
            <Spinner color={spinnerColor} />
          </StyledLoading>
          {/* children are in the loading wrapper
                to maintain the height/width of the
                button when showing the spinner */}
          <StyledChildren>{children}</StyledChildren>
        </StyledLoadingWrapper>
      ) : (
        children
      )}
    </StyledButton>
  );
};
