import styled, { css } from "styled-components";
import { StyledButtonProps } from "../../types/button";
import { colors } from "../../styles/variables";
import { ExternalLink } from "../links/ExternalLink";
import { InternalLink } from "../links/InternalLink";

/*----------
   styles
-----------*/
const transparentBgStyles = css`
  background-color: transparent;
  border-style: solid;
`;

const fillAndHoverStyles = css<StyledButtonProps>`
  ${({ addDisabledStyles }) =>
    addDisabledStyles ? disabledStyles : fillAndHoverDefaultStyles}
`;

const hollowStyles = css<StyledButtonProps>`
  ${({ color, theme }) => `
    ${transparentBgStyles};
    border-width: ${theme.button.hasThinBorder ? "1px" : "2px"};
    padding: ${
      theme.button.padding ||
      (theme.button.hasThinBorder ? "9px 24px" : "8px 23px")
    };
    color: ${color};
    border-color: ${color};
  `}
`;

const fillAndHoverDefaultStyles = css<StyledButtonProps>`
  ${({ color, textColor, hollow }) =>
    hollow
      ? hollowStyles
      : `
    background-color: ${color};
    color: ${textColor || colors.white};
    border: 0;
  `}

  &:hover {
    ${({ solidOnHover, hollowOnHover, color, textColor, disabled }) => {
      if (disabled) return;
      if (solidOnHover)
        return `
        background-color: ${color};
        color: ${textColor || colors.white};
      `;
      if (hollowOnHover) return hollowStyles;
      return `
        background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.15) 0%,
          rgba(0, 0, 0, 0.15) 100%
        )
      `;
    }}
  }
`;

const textStyles = css<StyledButtonProps>`
  background: none;
  border: none;
  text-transform: none;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 500;
  font-family: inherit;
  padding: 0;
  width: auto;
  ${({ theme, addDisabledStyles }) => {
    if (addDisabledStyles) {
      return `  border-color: ${theme.color.disabled};
      color: ${theme.color.disabled};
      cursor: not-allowed;`;
    }
    return `
    color: ${theme.button.text.color};`;
  }}
`;

const disabledStyles = css`
  cursor: not-allowed;

  ${({ theme }) =>
    theme.button.disabled?.hollow
      ? `
    border-color: ${theme.color.disabled};
    color: ${theme.color.disabled};
    ${transparentBgStyles};
    border-width: ${theme.button.hasThinBorder ? "1px" : "2px"};
    padding: ${
      theme.button.padding ||
      (theme.button.hasThinBorder ? "9px 24px" : "8px 23px")
    };
  `
      : `
    border: none;
    background-color: ${theme.color.disabled};
    color: ${colors.white};
  `}
`;

const smallStyles = css`
  padding-left: 0;
  padding-right: 0;
  font-weight: 700;
  text-align: center;
  width: 40px;
`;

const positionStyles = css<StyledButtonProps>`
  flex-grow: 0;
  ${({ position, theme }) => {
    const borderRadius =
      theme.button.borderRadius || theme.borderRadius || "4px";

    if (position === "left")
      return `
      border-radius: ${borderRadius} 0 0 ${borderRadius};
    `;
    if (position === "right")
      return `
      border-radius: 0 ${borderRadius} ${borderRadius} 0;
    `;
    return "";
  }}
`;

const themedStyles = css<StyledButtonProps>`
  ${({ styleType }) => {
    if (styleType === "text") return textStyles;
    return fillAndHoverStyles;
  }}
`;

const buttonStyles = css<StyledButtonProps>`
  box-sizing: border-box;
  /* THIS IS A TEMP CHANGE FOR G.COM UNTIL WE CAN UPDATE THE BUTTONS I HATE IT TOO */
  padding: ${({ theme }) => (theme.button.padding ? "19px 41px" : "10px 25px")};
  font-size: 16px;
  line-height: 1.25;
  cursor: pointer;
  text-align: center;
  border-radius: ${({ theme }) =>
    theme.button.borderRadius || theme.borderRadius || "4px"};
  font-family: ${({ theme }) => theme.button.fontFamily || "inherit"};
  font-weight: ${({ theme }) => theme.button.fontWeight};
  text-transform: ${({ theme }) => theme.button.textTransform};
  width: ${({ fullWidth }) => fullWidth && "100%"};
`;

/*----------
 components
-----------*/
export const StyledButton = styled.button<StyledButtonProps>`
  ${buttonStyles}
  ${themedStyles}
  ${({ small }) => small && smallStyles}
  ${({ position }) => position && positionStyles};
`;

export const StyledInternalLink = styled(InternalLink)<StyledButtonProps>`
  display: inline-block;
  ${buttonStyles}
  ${themedStyles}
`;

export const StyledExternalLink = styled(ExternalLink)<StyledButtonProps>`
  display: inline-block;
  ${buttonStyles}
  ${themedStyles}
`;

export const StyledLoadingWrapper = styled.div`
  position: relative;
`;

export const StyledLoading = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
`;

export const StyledChildren = styled.div`
  opacity: 0;
  user-select: none;
`;
