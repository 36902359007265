import React, { FC, ReactNode, useState, useEffect } from "react";
import URI from "urijs";
import { isEmail } from "../../../helpers/isEmail";
import { StyledAnchor } from "./index.styled";

interface ExternalLinkProps {
  href: string;
  children: ReactNode;
  // https://styled-components.com/docs/advanced#styling-normal-react-components
  // this is needed to extend styles, ex:
  //
  // const StyledExternalLink = styled(ExternalLink)`
  //   color: blue;
  // `;
  className?: string;
  onClick?: React.MouseEventHandler;
  openInSameTab?: boolean;
  underline?: boolean;
}

export const ExternalLink: FC<ExternalLinkProps> = ({
  href,
  children,
  className,
  onClick,
  openInSameTab,
  underline,
}) => {
  const [rel, setRel] = useState<string>();

  const constructHref = (href: string): string => {
    return isEmail(href) ? `mailto:${href}` : href;
  };

  useEffect(() => {
    if (href) {
      if (href.split(":")[0] === "mailto" || isEmail(href)) {
        setRel("noopener noreferrer nofollow");
      } else {
        // if internal link (starts with "/") don't need rel
        if (href[0] !== "/") {
          const uri = new URI(href);
          // if a pepsi domain don't add nofollow
          if (uri.domain().toLowerCase().includes("pepsi")) {
            setRel("noopener noreferrer");
          } else {
            setRel("noopener noreferrer nofollow");
          }
        }
      }
    }
  }, [href]);

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <StyledAnchor
      href={constructHref(href)}
      target={openInSameTab ? "_self" : "_blank"}
      rel={rel}
      data-testid="external-link-anchor"
      className={className || ""}
      onClick={onClick}
      underline={underline}
    >
      {children}
    </StyledAnchor>
  );
};
