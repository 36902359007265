import { ButtonType, ButtonTheme } from "../types/button";
import { ThemeInterface } from "../theme/theme";
import { colors } from "../styles/variables";

export const getSpinnerColor = (
  type: ButtonType,
  theme: ThemeInterface,
  isDisabled: boolean
): string => {
  if (type === "text") {
    return isDisabled ? theme.color.disabled : theme.button.text.color;
  }
  if (type === "white") return colors.white;
  if (isDisabled) {
    return theme.button.disabled?.hollow ? theme.color.disabled : colors.white;
  }
  const { color, hollow } = theme.button[type];
  return hollow ? color : colors.white;
};

export const getThemeValues = (
  type: ButtonType,
  theme: ThemeInterface
): ButtonTheme => {
  if (type === "text")
    return {
      color: theme.button.text.color,
      hollow: false,
      solidOnHover: false,
      hollowOnHover: false,
    };
  if (type === "white")
    return {
      color: "#fff",
      hollow: true,
      solidOnHover: false,
      hollowOnHover: false,
    };
  const { color, textColor, hollow, solidOnHover, hollowOnHover } =
    theme.button[type];
  return {
    color,
    textColor,
    hollow: !!hollow,
    solidOnHover: !!solidOnHover,
    hollowOnHover: !!hollowOnHover,
  };
};
