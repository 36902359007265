import styled, { keyframes, css } from "styled-components";
import { SpinnerProps } from ".";

const dotAnimation = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0) translateZ(0);
  }
  40% {
    transform: scale(1) translateZ(0);
  }
`;

const dotStyles = css<SpinnerProps>`
  width: ${({ dotSize }) => dotSize || "10px"};
  height: ${({ dotSize }) => dotSize || "10px"};
  background: ${({ color, theme }) => color || theme.color.primary};
  border-radius: 50%;
  display: inline-block;
  animation: ${dotAnimation} 1.4s infinite ease-in-out both;
`;

export const StyledDot1 = styled.div<SpinnerProps>`
  ${dotStyles};
  animation-delay: -0.32s;
`;

export const StyledDot2 = styled.div<SpinnerProps>`
  ${dotStyles};
  animation-delay: -0.16s;
`;

export const StyledDot3 = styled.div<SpinnerProps>`
  ${dotStyles};
`;

export const StyledSpinner = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  min-width: 50px;
  transform: translate(-50%, -50%);
`;

export const StyledSpinnerContainer = styled.div`
  position: relative;
  width: 100%;
`;
