import React, { FC } from "react";
import {
  StyledDot1,
  StyledDot2,
  StyledDot3,
  StyledSpinner,
  StyledSpinnerContainer,
} from "./index.styled";

export interface SpinnerProps {
  color?: string;
  dotSize?: string; // for height and width css values
  className?: string;
}

export const Spinner: FC<SpinnerProps> = ({ color, dotSize, className }) => {
  return (
    <StyledSpinnerContainer data-testid="spinner" className={className || ""}>
      <StyledSpinner>
        <StyledDot1 color={color} dotSize={dotSize}></StyledDot1>
        <StyledDot2 color={color} dotSize={dotSize}></StyledDot2>
        <StyledDot3 color={color} dotSize={dotSize}></StyledDot3>
      </StyledSpinner>
    </StyledSpinnerContainer>
  );
};
